import Hjem from "./components/Hjem";
import Navbar from "./components/Navbar";
import Meg from "./components/Meg";

function App() {
  return (
    <div >
      <Navbar />
      <Hjem />
      <Meg />
    </div>
  );
}

export default App;
