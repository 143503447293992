import React from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import { useState } from 'react'

const Navbar = () => {
    const [open, setOpen] = useState(false)
    const handleToggle = () => { setOpen(!open) }
  return (
    <div className="fixed w-full h-[90px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 border-y-2 " >
        <div className="font-poppins text-2xl">
            <h1 className='text-[#ff5c51] underline underline-offset-8'>Sakaria Ahmed</h1>
        </div>
        <div className="hidden md:flex">
            <ul className="flex">
                <li>Home</li>
                <li>Om meg</li>
                <li>Ferdigheter</li>
                <li>Kontakt</li>
            </ul>
        </div>

        {/* Hamburger meny */}
        <div onClick={handleToggle} className="md:hidden z-10 text-2xl">
            {!open ?  <FaBars /> : <FaTimes />}  
        </div>

        {/* mobil */}
        <div className={!open ? "hidden" :  "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"}>
            <ul>
                <li className='py-6 text-4xl'>Hjem</li>
                <li className='py-6 text-4xl'>Om meg</li>
                <li className='py-6 text-4xl'>Ferdigheter</li>
                <li className='py-6 text-4xl'>Kontakt</li>
            </ul>
        </div>
    </div>
  )
}

export default Navbar