import React from 'react'

const Meg = () => {
  return (
    <div name="Meg" className="w-full h-screen bg-[#0a192f] text-gray-300 border">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8">

          <h1>Meg</h1>
          <p></p>
        </div>
      </div>
    </div>
  )
}

export default Meg  