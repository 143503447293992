import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'

const Hjem = () => {
  return (
      <div name="Hjem" className="w-full h-screen bg-[#0a192f] border">
        <div className="bg-[#0a192f] max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
            <p className="text-[#ff5c51]">Hei, jeg heter</p>
            <h1 className="text-4xl font-bold sm:text-7xl text-[#ffffff]">Sakaria Ahmed</h1>
            <h2 className='text-4xl text-gray-400   sm:text-7xl font-bold'>Jeg er en student på UiO</h2>
            <p></p>
            <div>
                <button className="text-white group border-2 px-6 py-3 my-2 mt-8 l:mt-3 flex items-center hover:bg-[#ff5c51] hover:border-[#ff5c51]">Sjekk ut verkene mine
                <span className="group-hover:rotate-90 duration-300">
                  <HiArrowNarrowRight className='ml-3'/>
                </span></button>
            </div>
        </div>
      </div>

  )
}

export default Hjem